export const PROVIDER_ADDRESS = "PROVIDER_ADDRESS";
export const PROVIDER_INFO = "PROVIDER_INFO";
export const CLIENT_REQUEST = "CLIENT_REQUEST";

/**
 *  Article
 */
export const ARTICLES_ALL = "ARTICLES_ALL";
export const CATEGORY_LIST = "CATEGORY_LIST";
export const ARTICLE_DETAILS_DISPLAY = "ARTICLE_DETAILS_DISPLAY";
export const POST_COMMENT = "POST_COMMENT";

export const FILE_UPLOAD = "FILE_UPLOAD";
export const FILE_UPLOAD_ERROR = "FILE_UPLOAD_ERROR";
export const GET_COMMENT_BYID = "GET_COMMENT_BYID";
export const GET_RECENT_ARTICLES = "GET_RECENT_ARTICLES";

/**
  * Help Center
 */
export const HELP_COUNT_ARTICLE = "HELP_COUNT_ARTICLE";
export const HELP_COUNT_ARTICLE_CATEGORY = 'HELP_COUNT_ARTICLE_CATEGORY';
export const HELP_ARTICLES_CATEGORY = "HELP_ARTICLES_CATEGORY";
export const HELP_ARTICLE_ID = "HELP_ARTICLE_ID";
export const HELP_LIKE = "HELP_LIKE";
export const BLOG_LIKE = "BLOG_LIKE";
export const ARTICLE_BY_SEARCH = "ARTICLE_BY_SEARCH";

/**
 * Send Contact
 */
export const SEND_CONTACT = "SEND_CONTACT";
export const SHOW_SPINNING = "SHOW_SPINNING";

/**
 * chatbot
 */
export const CHATBOT_PAGE = "CHATBOT_PAGE";
export const GET_CHATBOT = 'GET_CHATBOT';
export const GET_REPLY = "GET_REPLY";
export const GET_FAIL = "GET_FAIL";