export const DEPARTMENTS = {
    acceptance_commitment: {
        picture: 'search-img0-1.svg',
        title: 'Acceptance and Commitment Therapy'
    },
    acute: {
        picture: 'search-img0-2.svg',
        title: 'Acute and Chronic Medical Conditions'
    },
    recovery: {picture: 'search-img1-1.svg', title: 'Addictions/Recovery'},
    adhd: {picture: 'search-img1-2.svg', title: 'ADHD/ADD'},
    adolescents: {picture: 'search-img1-3.svg', title: 'Adolescents'},
    african: {picture: 'search-img2-1.svg', title: 'African American'},
    management: {picture: 'search-img2-2.svg', title: 'Anger Management'},
    anxiety: {picture: 'search-img2-3.svg', title: 'Anxiety'},
    assessment: {picture: 'search-img3-1.svg', title: 'Assessment'},
    autism: {picture: 'search-img3-2.svg', title: 'Autism Spectrum Disorder'},
    disorder: {picture: 'search-img3-3.svg', title: 'Bipolar Disorder'},
    borderline: {picture: 'search-img4-1.svg', title: 'Borderline Personality Disorder'},
    chronic_illness_disability: {
        picture: 'search-img4-2.svg',
        title: 'Chronic Illness & Disability'
    },
    clinical: {picture: 'search-img4-3.svg', title: 'Clinical Hypnosis'},
    clinical_supervision: {picture: 'search-img5-1.svg', title: 'Clinical Supervision'},
    codependecy: {picture: 'search-img5-2.svg', title: 'Codependecy'},
    cognitive_behavioral_therapy: {
        picture: 'search-img5-3.svg',
        title: 'Cognitive Behavioral Therapy'
    },
    college_students: {picture: 'search-img6-1.svg', title: 'College Students'},
    compassion: {picture: 'search-img6-2.svg', title: 'Compassion Fatigue & Burnout'},
    coping_skills: {picture: 'search-img6-3.svg', title: 'Coping Skills'},
    couples_marriage: {picture: 'search-img7-1.svg', title: 'Couples/Marriage'},
    depression: {picture: 'search-img7-2.svg', title: 'Depression'},
    diabetes: {picture: 'search-img7-3.svg', title: 'Diabetes'},
    divorce: {picture: 'search-img8-1.svg', title: 'Divorce'},
    eating_disorders: {picture: 'search-img8-2.svg', title: 'Eating Disorders'},
    emdr: {picture: 'search-img8-3.svg', title: 'EMDR'},
    erectile: {picture: 'search-img9-1.svg', title: 'Erectile Dysfunction'},
    family: {picture: 'search-img9-2.svg', title: 'Family'},
    forensic_psychology: {picture: 'search-img9-3.svg', title: 'Forensic Psychology'},
    gender: {picture: 'search-img10-1.svg', title: 'Gender and Sexual Diversity'},
    grief: {picture: 'search-img10-2.svg', title: 'Grief'},
    immigrant: {
        picture: 'search-img10-3.svg',
        title: 'Immigrant and Refugee Mental Health'
    },
    indigenous_first_nations: {
        picture: 'search-img11-1.svg',
        title: 'Indigenous & First Nations & Native Americans'
    },
    infertility: {picture: 'search-img11-2.svg', title: 'Infertility'},
    intimacy_sexual: {picture: 'search-img11-3.svg', title: 'Intimacy & Sexual Health'},
    lgbtqia: {picture: 'search-img12-1.svg', title: 'LGBTQIA'},
    life_transitions: {picture: 'search-img12-2.svg', title: 'Life Transitions'},
    health_men: {picture: 'search-img12-3.svg', title: 'Men’s Issues & Health'},
    metabolic: {picture: 'search-img13-1.svg', title: 'Metabolic Syndrome'},
    military_issues: {picture: 'search-img13-2.svg', title: 'Military Related Issues'},
    mindfulness: {picture: 'search-img13-3.svg', title: 'Mindfulness'},
    motivation: {picture: 'search-img14-1.svg', title: 'Motivation & Goal Achievement'},
    narcissism: {picture: 'search-img14-2.svg', title: 'Narcissism'},
    obesity: {picture: 'search-img14-3.svg', title: 'Obesity'},
    obsessive: {picture: 'search-img15-1.svg', title: 'Obsessive Compulsive Disorder'},
    parenting: {picture: 'search-img15-2.svg', title: 'Parenting'},
    pastors: {picture: 'search-img15-3.svg', title: 'Pastors'},
    phobias: {picture: 'search-img16-1.svg', title: 'Phobias'},
    post_traumatic: {picture: 'search-img16-2.svg', title: 'Post-Traumatic Stress Disorder'},
    postpartum: {picture: 'search-img16-3.svg', title: 'Postpartum Depression'},
    relationships: {picture: 'search-img17-1.svg', title: 'Relationships'},
    schizophrenia: {picture: 'search-img17-2.svg', title: 'Schizophrenia'},
    self_esteem: {picture: 'search-img17-3.svg', title: 'Self-Esteem'},
    sexual_abuse: {picture: 'search-img18-1.svg', title: 'Sexual Abuse'},
    sexual_addiction: {picture: 'search-img18-2.svg', title: 'Sexual Addiction'},
    social_search: {
        picture: 'search-img18-3.svg',
        title: 'Social Justice/Search for Meaning'
    },
    spirituality_faith: {picture: 'search-img19-1.svg', title: 'Spirituality/Faith'},
    sport: {
        picture: 'search-img19-2.svg',
        title: 'Sport Performance and Biofeedback'
    },
    stress: {picture: 'search-img19-3.svg', title: 'Stress'},
    trauma_abuse: {picture: 'search-img20-1.svg', title: 'Trauma/Abuse'},
    muscle_skeleton: {
        picture: 'search-img20-2.svg',
        title: 'Wellness of Musculoskeletal System'
    },
    woman_issue: {picture: 'search-img20-3.svg', title: 'Women’s Issues & Health'},
    work_issue: {picture: 'search-img21.svg', title: 'Work Issues/Performance'},
};

export const SPECIAL_CATEGORIES = {
    addiction: 'Addiction Specialist',
    art: 'Art Therapist',
    certified: 'Certified Clinical Anxiety',
    treatment: 'Treatment Professional',
    certified_sex: 'Certified Sex Therapist',
    certified_trauma: 'Certified Trauma Specialist',
    clinical: 'Clinical Hypnotherapist',
    coach: 'Coach',
    consultant: 'Consultant',
    counselor: 'Counselor',
    board_pastoral: 'Board Certified Pastoral Counselor',
    dietitian: 'Dietitian',
    distance: 'Distance Credentialed Counselor',
    marriage: 'Marriage & Family Therapist',
    mental_health: 'Maternal Mental Health Specialist',
    mental_specialist: 'Mental Health Specialist',
    mental_therapist: 'Mental Health Therapist',
    nurse: 'Nurse Practitioner',
    occupational: 'Occupational Therapist',
    physical: 'Physical Therapist',
    play: 'Play Therapist',
    primary: 'Primary Health Care',
    psychiatrist: 'Psychiatrist',
    psychologist: 'Psychologist',
    psychotherapist: 'Psychotherapist',
    psychotherapy: 'Psychotherapy Practice',
    sexologist: 'Sexologist',
    social: 'Social Worker',
    speech: 'Speech Therapist',
};
export const FILTER_LIST = {
    acceptance_commitment: 'Acceptance and Commitment Therapy',
    adolescents: 'Adolescents',
    assessment: 'Assessment',
    chronic_illness_disability: 'Chronic Illness & Disability',
    cognitive_behavioral_therapy: 'Cognitive Behavioral Therapy',
    couples_marriage: 'Couples/Marriage',
    eating_disorders: 'Eating Disorders',
    forensic_psychology: 'Forensic Psychology',
    indigenous_first_nations: 'Indigenous & First Nations & Native Americans',
    life_transitions: 'Life Transitions',
    mindfulness: 'Mindfulness',
    obsessive: 'Obsessive Compulsive Disorder',
    post_traumatic: 'Post-Traumatic Stress Disorder',
    self_esteem: 'Self-Esteem',
    spirituality_faith: 'Spirituality/Faith',
    wellness: 'Wellness of Musculoskeletal System',

    acute: 'Acute and Chronic Medical Conditions',
    african: 'African American',
    autism: 'Autism Spectrum Disorder',
    clinical: 'Clinical Hypnosis',
    college_students: 'College Students',
    depression: 'Depression',
    emdr: 'EMDR',
    gender: 'Gender and sexual diversity',
    infertility: 'Infertility',
    mens_issues: 'Men’s Issues & Health',
    motivation: 'Motivation & Goal Achievement',
    parenting: 'Parenting',
    postpartum: 'Postpartum Depression',
    sexual_abuse: 'Sexual Abuse',
    sport: 'Sport Performance and Biofeedback',
    women_health: 'Women’s Issues & Health',

    addictions_recovery: 'Addictions/Recovery',
    anger_management: 'Anger Management',
    bipolar_disorder: 'Bipolar Disorder',
    clinical_supervision: 'Clinical Supervision',
    compassion: 'Compassion Fatigue & Burnout',
    diabetes: 'Diabetes',
    erectile: 'Erectile Dysfunction',
    grief: 'Grief',
    intimacy_sexual: 'Intimacy & Sexual Health',
    metabolic: 'Metabolic Syndrome',
    narcissism: 'Narcissism',
    pastors: 'Pastors',
    relationships: 'Relationships',
    sexual_addiction: 'Sexual Addiction',
    stress: 'Stress',
    work_issues: 'Work Issues/Performance',

    adhd: 'ADHD/ADD',
    anxiety: 'Anxiety',
    borderline: 'Borderline Personality Disorder',
    codependecy: 'Codependecy',
    coping_skills: 'Coping Skills',
    divorce: 'Divorce',
    family: 'Family',
    immigrant: 'Immigrant and Refugee Mental Health',
    lgbtqia: 'LGBTQIA',
    military_issues: 'Military Related Issues',
    obesity: 'Obesity',
    phobias: 'Phobias',
    schizophrenia: 'Schizophrenia',
    social_justice: 'Social Justice/Search for Meaning',
    trauma_abuse: 'Trauma/Abuse',
    wellness_musculoskeletal: 'Wellness of Musculoskeletal System',
    woman_issue: 'Women’s Issues & Health',
    work_issue: 'Work Issues/Performance',
};