export default {
	SIM_API_URL: "/api/",
	FRONT_URL: 'http://teletherapist.care',
	CLIENT_URL: 'https://client.teletherapist.care',
	PROVIDER_URL: 'https://provider.teletherapist.care',

	// SIM_API_URL: "http://192.168.1.6:7000/",
	// FRONT_URL: 'http://localhost:3000',
	// CLIENT_URL: 'http://localhost:3001',
	// PROVIDER_URL: 'http://localhost:3002',
};