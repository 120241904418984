import React from 'react';
import Footer from './footer';
import Body from './body';

function Home() {
    return (
        <>
            <Body></Body>

            <Footer></Footer>
        </>
    );
}

export default Home;